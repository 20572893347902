@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-ExtraBold.woff2") format("woff2"),
        url("../fonts/OpenSans-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-BoldItalic.woff2") format("woff2"),
        url("../fonts/OpenSans-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Bold.woff2") format("woff2"), url("../fonts/OpenSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Light.woff2") format("woff2"), url("../fonts/OpenSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-LightItalic.woff2") format("woff2"),
        url("../fonts/OpenSans-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Regular.woff2") format("woff2"), url("../fonts/OpenSans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-SemiBold.woff2") format("woff2"), url("../fonts/OpenSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
        url("../fonts/OpenSans-ExtraBoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
        url("../fonts/OpenSans-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Open Sans";
    src: url("../fonts/OpenSans-Italic.woff2") format("woff2"), url("../fonts/OpenSans-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

$generalFonts: "Segoe Ui", "Open Sans", Tahoma, Geneva, Verdana, sans-serif;

// fonts end, anim start

@-webkit-keyframes BackgroundAnim {
    0% {
        background-position: 84% 0%;
    }
    50% {
        background-position: 17% 100%;
    }
    100% {
        background-position: 84% 0%;
    }
}

@-moz-keyframes BackgroundAnim {
    0% {
        background-position: 84% 0%;
    }
    50% {
        background-position: 17% 100%;
    }
    100% {
        background-position: 84% 0%;
    }
}
@keyframes BackgroundAnim {
    0% {
        background-position: 84% 0%;
    }
    50% {
        background-position: 17% 100%;
    }
    100% {
        background-position: 84% 0%;
    }
}

//

body {
    margin: 0;
    font-family: $generalFonts;
    color: white;
}

html {
    background-color: #20002e;
}

body,
html {
    height: 100%;
    overflow-x: hidden;
}

body {
    overflow-y: scroll;
}

#root {
    height: 100%;
}

.sc {
    font-variant: small-caps;
}

.b {
    font-weight: bold;
}

button.newButton {
    padding: 0;
    background-color: transparent;
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    svg {
        display: block;
        g {
            transition: opacity 300ms;
        }

        font-family: $generalFonts;
        text,
        .text,
        tspan {
            max-width: 100%;
            overflow: scroll;
            font-weight: bold;
            transition: opacity 300ms;
        }
        rect {
            fill: white;
            opacity: 1;
            transition: opacity 300ms;
        }
        rect.dull {
            opacity: 0;
            &.shaded {
                opacity: 0.03;
            }

            transition: opacity 300ms;
        }
    }

    &.noTransition {
        svg rect,
        svg text,
        svg .text,
        svg tspan,
        svg g {
            transition: none;
        }
    }

    &:hover {
        svg rect {
            opacity: 0.9;
            &.dull {
                opacity: 0.05;
            }
        }
    }
    &:active {
        svg rect {
            opacity: 0.8;
            &.dull {
                opacity: 0.1;
            }
        }
    }
}

button.newButton:disabled {
    svg rect {
        opacity: 0.5;
        &.dull {
            opacity: 0;
        }
    }
    svg text,
    svg .text,
    svg tspan {
        text-decoration: line-through;
        &.dull {
            opacity: 0.5;
        }
    }
}

button.newButton:disabled {
    svg rect {
        opacity: 0.5;
    }
    svg text,
    svg tspan {
        text-decoration: line-through;
    }
}

#hiddenBox {
    position: absolute;
    left: -300vw;
    top: -400vh;
    font-weight: bold;
}

.connectingPage {
    // margin: 0 auto;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid white;
    padding: 20px;
    margin: 0 auto;
}

a {
    color: rgb(255, 255, 255);
    font-weight: bold;
}

#movingBackground {
    display: block;
    z-index: -1;
    touch-action: none;
    background: linear-gradient(2deg, #0e0021, #1e0047, #b71f1f);
    // background: linear-gradient(2deg, #110621, #250c47, #b83e3e);
    
    // background: linear-gradient(2deg, #185eb9, #b83e3e);
    background-size: 400% 400%;
    -webkit-animation: BackgroundAnim 30s ease infinite;
    -moz-animation: BackgroundAnim 30s ease infinite;
    animation: BackgroundAnim 30s ease infinite;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
}

.noListStyle {
    padding: 0;
    margin: 0;
    list-style: none;
}

.noListBullet {
    padding-top: 0;
    margin: 0;
    list-style: none;
}

.noListPadding {
    padding-top: 0;
    margin: 0;
}

.slickContainer {
    overflow: hidden;

    .parent {
        width: 100%;
        margin: 0 auto;
        // overflow: hidden;

        height: 100%;
    }
    .central {
        // overflow: hidden;
        display: flex;

        .object {
            box-sizing: border-box;
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
            opacity: 0.1;
            position: relative;
            height: 100%;
        }
        .object.active {
            opacity: 1;
        }
    }
}

.slickContainer.centralSC {
    height: 100%;
    .central {
        height: 100%;
    }
}

#main {
    margin: 0 auto;
    // box-sizing: border-box;
    border: 2px solid #ffffff15;
    border-bottom: none;
    border-top: none;
    background-color: #ffffff04;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    user-select: none;

    width: 598px;

    min-height: 100%;
    // height: 100%;
}

.bannerImage {
    display: block;
    margin: 0 auto;
    width: 14%;
    padding-top: 20px;
}

.centralMain {
    height: 100%;
}

.containerCentralSC {
    height: 100%;
}

.centralSC .title {
    // margin-top: 16.5%;
    // transition: margin 500ms, background-color 2s;
    font-variant: small-caps;
    text-transform: lowercase;
    width: 100%;
    font-size: 2em;
    text-align: center;
    line-height: normal;
}

.division {
    border-top: 1.5px solid #fff;
    width: 70%;
    padding: 1.5px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;

    transition: opacity 500ms, padding 500ms, margin 400ms, width 600ms, border 500ms;
}

.centralSC {
    height: 100%;
    overflow: hidden;
    // display: flex;
    // flex-direction: column;

    .pageContainer {
        padding-top: 10px;
        padding-left: 25px;
        padding-right: 25px;
        // flex: 1;
        overflow: hidden;

        height: 100%;
        max-width: 100%;
        overflow-x: hidden;
        // box-sizing: border-box;
        box-sizing: content-box;
        // padding-bottom: 600px;
        scrollbar-color: white #ffffff00;
        -webkit-overflow-scrolling: touch;
        // overflow-scrolling: touch;
        padding-bottom: 15vh;
    }
}

.specialBar {
    position: absolute;

    overflow: hidden;

    width: 598px;
    z-index: 9999;
    max-width: 100%;

    &.top {
        top: 0;
        bottom: unset;
    }

    &.bottom {
        bottom: 0;
        top: unset;
    }

    .specialBarHub {
        // position: fixed;
        position: fixed;

        width: 600px;
        max-width: 100%;

        display: flex;
        justify-content: space-between;

        overflow: hidden;

        &.top.inactive {
            transform: translateY(-110%);
        }

        &.top.active {
            transform: unset;
        }

        &.bottom.inactive {
            transform: translateY(110%);
        }

        &.bottom.active {
            transform: unset;
        }

        transition: opacity 400ms, transform 400ms, margin 300ms;

        &.top {
            top: 0;
            bottom: unset;
        }
        &.bottom {
            display: none;
            top: unset;
            bottom: 0;
        }

        .centre {
            width: 100%;
        }

        .newButton {
            min-width: 25%;
            max-height: 20%;

            &.invalid {
                opacity: 0;
            }

            svg {
                width: 100%;
                // height: 100%;
            }

            overflow: hidden;
            // overflow: hidden;

            border: 2px solid rgb(255, 255, 255, 0.15);

            transition: opacity 400ms, transform 400ms;

            border-top: unset;

            &.first,
            &.left {
                border-left: unset;
                border-bottom-right-radius: 20px;
            }
            &.last,
            &.right {
                border-right: unset;
                border-bottom-left-radius: 20px;
            }

            // &:active:not(.dullElement) {
            //     opacity: 1;
            // }
        }

        &.top .newButton.inactive {
            transform: translateY(-110%);
        }

        &.bottom .newButton.inactive {
            transform: translateY(110%);
        }

        &.bottom {
            .newButton {
                border: 2px solid rgb(255, 255, 255, 0.15);
                border-bottom: unset;

                &.first,
                &.left {
                    border-left: unset;
                    border-bottom-right-radius: unset;

                    border-top-right-radius: 20px;
                }
                &.last,
                &.right {
                    border-right: unset;
                    border-bottom-left-radius: unset;

                    border-top-left-radius: 20px;
                }
            }

            .centre {
                display: none;
            }
        }

        &.hiddenUI {
            .newButton {
                &.first,
                &.left {
                    transform: translateX(-110%);
                }
                &.last,
                &.right {
                    transform: translateX(110%);
                }
            }
        }

        &.hidden {
            &.bottom .newButton {
                transform: translateY(110%);
            }

            &.top .newButton {
                transform: translateY(-110%);
            }
        }

        &.noTransition {
            transition: none;
        }
    }

    &.noTransition {
        transition: none;
    }
}

.menuBarMobile {
    width: 598px;

    max-width: 100%;
    display: none;

    position: unset;
    left: unset;
    top: unset;
    flex-direction: row;

    .item {
        flex: 1 0 0;
        // padding: 2px;
        // max-height: 50px;
        text-align: center;
        vertical-align: text-top;
        font-weight: 600;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.4);
        margin-bottom: -20%;
        transition: margin 300ms;
    }

    button {
        padding: 0;
    }

    button svg {
        width: 100%;
        height: 100%;
        // display: block;
    }
}

.menuBarDesktop {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    flex-direction: column;

    transform: translateX(-100%);
    transition: transform 300ms;

    .item {
        flex: 1;
        // background-color: pink;
        display: block;
        // padding: 2px;
        // max-height: 50px;
        text-align: center;
        vertical-align: text-top;
        font-weight: 600;
        overflow: hidden;
    }

    button {
        width: 100%;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .newButton.hover {
        svg rect.dull {
            opacity: 1;
        }
    }

    border-right: 2px solid rgba(255, 255, 255, 0.08);
    border-bottom: 2px solid rgba(255, 255, 255, 0.08);
    border-bottom-right-radius: 20px;

    overflow: hidden;

    button svg {
        width: 100%;
        // height: 100%;
        // ;
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: white;

    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: lightgrey;
}

@media only screen and (max-width: 400px) {
    .centralSC .title {
        font-size: 1.5em;
    }
    .centralSC .title {
        // margin-top: 18%;
    }
}

@media only screen and (max-width: 600px) {
    #main {
        width: 100%;
        background-color: unset;
        border: unset;
    }

    .bannerImage {
        width: 16%;
    }

    #hiddenBox {
        position: absolute;
        left: -300vw;
        top: -400vh;
        font-weight: bold;
        font-size: 120%;
    }

    button.newButton svg text {
        font-size: 120%;
    }

    .centralSC .title {
        // margin-top: 19%;
    }
}

@media only screen and (max-width: 950px) {
    .menuBarMobile {
        display: flex;
        z-index: 999;
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 0 auto;

        left: 50%;
        transform: translateX(-50%);

        &.active {
            .item {
                margin-bottom: unset;
            }
        }
    }
}

@media only screen and (min-width: 950px) {
    .menuBarDesktop.active {
        transform: none;
    }
}

input[type="text"],
input[type="email"],
input[type="password"],
.regularText {
    &:not(.standard) {
        background-color: transparent;
        border: 1px solid white;
        border-radius: 25px;
        margin: 0 auto;
        display: block;
        padding: 13px;
        color: white;
        width: 90%;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 100%;
        font-family: $generalFonts;
        box-sizing: border-box;
    }
}


.titleBar {
    transition: margin 100ms, background-color 400ms;
}

.titleBar.hidden {
    .title {
        margin-top: 0;

        margin: 0 auto;
        overflow: hidden;
        padding-bottom: 5px;
    }

    background-color: rgb(255, 255, 255, 0.03);

    .division {
        opacity: 1;
        border-top: 1.5px solid #ffffff15;
        box-sizing: border-box;
        padding: 0;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}

img:not(.nS) {
    max-width: 100%;
    border-radius: 4vw;
    border: 2px solid rgba(255, 255, 255, 0.2);
    display: block;
    margin: 0 auto;
    margin-top: 20px;
}

img.regimenImage {
    box-sizing: border-box;
    border-radius: 2vw;
}

.coolSwitch {
    display: flex;
    border: 1px solid white;
    border-radius: 25px;
    overflow: hidden;

    .newButton {
        display: block;
    }
}

.ninety {
    width: 90%;
    margin: 0 auto;
}

.fifty
{
    width: 50%;
    margin: 0 auto;
}


.subdivision {
    &:not(:first-of-type) {
        margin-top: 10px;
    }
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
}

.bold {
    font-weight: bold;
}

.fakeLink {
    cursor: pointer;
    text-decoration: underline;
}

.halfButton {
    display: block;
    width: 50%;
}

// pages below

.about {
    .centreSection {
        text-align: center;
    }
}

.joinPage {
    .submit,
    .coolSwitch {
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .problem {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .continueContainer {
        display: flex;
        margin: 0 auto;
        gap: 10px;
    }
}

.profile {
    .username {
        font-variant: small-caps;
        font-size: 25pt;
        text-align: center;
    }
    .bio {
        text-align: center;
    }
    .timestamp {
        text-align: center;
        font-size: 90%;
    }
    .profilePicture {
        width: 90%;
        display: block;
        margin: 0 auto;
        margin-top: 10px;
    }
    .regularText {
        padding: 8px;
        overflow: visible;
        resize: none;
        // height: 60px;
        text-align: center;
        outline: none;
        box-sizing: content-box;
        display: block;
        max-width: 80%;
        border-color: #ffffff2f;
        border-width: 2px;
    }
    .profilePictureContainer {
        position: relative;

        cursor: pointer;
        .editButton {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            z-index: 999;
            font-weight: bold;
        }
        .profilePicture {
            filter: brightness(30%);
        }
    }
    .groupInfo {
        margin-top: 10px;
    }

    .searchError {
        margin-top: 10px;
    }

    .friendInfo {
        text-align: center;
    }

    .friendMessage {
        text-align: center;
        margin-top: 10px;
        font-variant: small-caps;
    }

    .searchingUser {

        align-items: center;
        display: flex;
        justify-content: center;
        gap: 5px;
        width: 90%;
        margin: 0 auto;
        margin-top: 10px;
        .regularText {
            max-width: 60%;
            display: inline-block;
            margin: 0;
            flex-grow: 9;
            flex-shrink: 0;
        }
        .newButton    {
            flex-grow: 1;
        }
    }
}

.noTransition {
    transition: none;
}

.hidden {
    display: none;
}

.perf .object {
    transform: translate3d(0, 0, 0); // FUCK YOU SAFARI
}

.perfAll * {
    transform: translate3d(0, 0, 0); // FUCK YOU SAFARI
}
